import { type Product } from '@/infra/services/home/queries/modules/index.schemas';

type BannerPromotionDataParams = {
  imageAlt: string;
  moduleType: string;
  title: string;
  position?: number;
};

export const bannerPromotionData = ({
  imageAlt,
  moduleType,
  title,
  position,
}: BannerPromotionDataParams) => {
  return {
    id: moduleType,
    name: title,
    creative: imageAlt,
    ...(position && { position: `slot${position}` }),
  };
};

type ProductDataParams = {
  product: Product;
  position: number;
  moduleTitle?: string;
};

export const productData = ({
  product,
  position,
  moduleTitle,
}: ProductDataParams) => {
  return {
    name: product.shortName,
    id: product.id,
    price: product.price.to?.toFixed(2),
    brand: product.brand,
    category: product.categoryTree,
    position: position,
    ...(moduleTitle ? { list: moduleTitle } : {}),
  };
};
